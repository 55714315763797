import { defaultI18nNs } from '@cocoonspace/shared/config/default-i-18-n-ns-config'
import { SearchIcon } from 'lucide-react'
import type { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { HeaderIcon } from '~/components/ui/header-icon'

const Page = () => {
	const { t } = useTranslation()

	return (
		<div className='flex min-h-[60vh] flex-col items-center justify-center gap-4'>
			<HeaderIcon variant='gray'>
				<SearchIcon size={26} />
			</HeaderIcon>

			<p className='font-bold text-lg'>{t('common:404.title')}</p>
		</div>
	)
}

export default Page

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
	props: {
		...(await serverSideTranslations(locale as string, [
			...defaultI18nNs,
			'common',
			'extras',
			'quotations',
			'actions',
			'modules',
			'attributesLabels',
			'values',
			'sectionsLabels',
			'linksLabels',
		])),
	},
})
